const cursorGrabbing = () => {
  const bodyElement: HTMLElement = document.body;
  bodyElement.classList.add('inheritCursors');
  bodyElement.style.cursor = 'grabbing';
};

const cursorReset = () => {
  const bodyElement: HTMLElement = document.body;
  bodyElement.style.cursor = 'grab';
  setTimeout(() => {
    bodyElement.classList.remove('inheritCursors');
    bodyElement.style.cursor = 'unset';
  }, 200);
};

export const dragDropCursorUtil = {
  cursorGrabbing,
  cursorReset,
};
